ion-header.profile-page-header {
  padding-top: 8px;

  ion-toolbar {
    ion-buttons {
      ion-button {
        height: 100%;

        ion-icon {
          border-radius: 50%;
          box-sizing: border-box;

          &.add {
            padding: 6px;
            height: 25px;
            width: 25px;
            background: white;
            color: black;
          }

          &.menu {
            padding: 6px;
            height: 25px;
            width: 25px;
            color: white;
          }
        }
      }
    }
  }
}

ion-content {

  .profile-page {
    width: 100%;
    position: relative;
    overflow: auto;

    .header {
      width: 100%;
      position: relative;
      overflow: hidden;
      background-color: black;

      &.expanded-header {
        height: auto;

        .back {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
          padding: 10px 10px 20px 10px;
          display: grid;
          overflow: hidden;
          vertical-align: center;

          img {
            width: 100%;
          }
        }

        .front {
          height: auto;
          z-index: 10;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .info {
            z-index: 5;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            app-avatar {
              margin: 55px 0 0;
            }

            .name {
              margin-top: 30px;
              font-weight: 500;
              font-size: 24px;
              color: white;
            }

            .description {
              color: white;
              font-weight: 300;
              font-size: 12px;
              margin-top: 10px;
              margin-right: 30px;
              margin-left: 30px;
              text-align: center;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              word-break: break-word;
            }

            .rating-info {
              margin-top: 10px;
              margin-bottom: 10px;
              font-size: 12px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              color: white;

              .stars {
                .value {
                  margin-right: 3px;
                }

                .icon {
                  height: 11px;
                }
              }

              .visitors {
                .value {
                  margin-left: 3px;
                }

                .icon {
                  position: relative;
                  top: 1px;
                  height: 11px;
                }
              }

              .rate {
                .value {
                  margin-left: 3px;
                }

                .icon {
                  position: relative;
                  top: 1px;
                  height: 11px;
                }
              }
            }

            .suggest-btn {
            }
          }
        }
      }

      &.compressed-header {
        height: 50px;
        width: 100%;
        z-index: 3;
        position: sticky;
        margin-top: -50px;
        top: 0;
        opacity: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 0 15px 15px;
        background-color: black;

        .back-btn {
          flex-grow: 0;
          flex-shrink: 0;
        }

        .name {
          flex-grow: 1;
          flex-shrink: 1;
          font-size: 18px;
          color: white;
        }

        .buttons {
          flex-grow: 0;
          flex-shrink: 0;
          width: min-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding-right: 8px;

          ion-icon {
            border-radius: 50%;
            box-sizing: border-box;

            &.add {
              padding: 6px;
              height: 25px;
              width: 25px;
              background: white;
              color: black;
              margin-right: 8px;
            }

            &.menu {
              padding: 6px;
              height: 25px;
              width: 25px;
              color: white;
            }
          }
        }
      }
    }

    .info {
      app-avatar {
        font-size: xx-large;
      }
    }

    .profile-segments {
      ion-segment {
        ion-segment-button {
          min-height: 35px;
        }

        ion-label {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }

    ion-button.add-description-button {
      --padding-start: 15px;
      --padding-end: 15px;
      --padding-top: 10px;
      --padding-bottom: 10px;
      height: auto;
    }

    .files-container, .streams-container, .rating-container {

      ion-skeleton-text {
        margin: 0;
      }
    }

    .files-slot {

      .files-skeleton-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 9px;

        .file-skeleton {
          aspect-ratio: 1;
          border-radius: 10px;
        }
      }

      .files-container {
        width: fit-content;
        max-width: initial;
        display: flex;
      }

      .load-file-button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        ion-button {
          height: 40px;
          width: 150px;
          margin: 65px auto 0 auto;
        }
      }
    }

    .streams-slot {

      .streams-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        .video-container, .video-skeleton {
          aspect-ratio: 9 / 16;
          border-radius: 15px;
          border: 1px solid grey;
          overflow: hidden;
        }
      }
    }

    .rating-slot {

      .rating-container {

        .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .title {
            font-size: 22px;
            font-weight: 600;
          }

          .star {
            font-size: 18px;

            ion-icon {
              display: inline-block;
              margin-right: 5px;
              margin-bottom: 1px;
              width: 12px;
              height: 12px;
            }
          }
        }

        .rating {
          padding: 15px 0;
          border-bottom: 1px solid rgba(131, 131, 131, 0.5);

          .my-rating {
            font-size: 16px;
            font-weight: 500;
          }

          .not-my-rating {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .avatar {
              flex-shrink: 0;
              flex-grow: 0;
              margin-right: 10px;
            }

            .name {
              flex-shrink: 1;
              flex-grow: 1;
              text-overflow: ellipsis;
            }
          }

          .rate-info {
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .stars {
              margin-right: 10px;
            }

            .date {
              font-size: 11px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0;
              text-align: left;
              color: #A4A39B;
              vertical-align: middle;
              padding-top: 3px;
            }
          }

          .category {
            margin-top: 5px;
            font-size: 11px;
            font-weight: 400;
            line-height: 12px;
            text-align: left;
            color: #A4A39B;
          }

          .description {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-align: left;
            color: white;
            margin-top: 10px;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .empty {
      width: 100%;
      color: var(--color-grey-0);
      //padding-top: 20%;
      margin: 65px auto 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

ion-content.ios {
  .files-container, .streams-container {
    padding-bottom: 80px;
  }

  .rating-container {
    padding-bottom: 90px;
  }
}

ion-content.md {
  .files-container, .streams-container {
    padding-bottom: 55px;
  }

  .rating-container {
    padding-bottom: 65px;
  }
}