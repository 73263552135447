.preview-modal {
  --width: min(80%, 310px);
  --height: 85%;
  --border-radius: 15px;
  --background: black;
  padding-bottom: 15%;

  ::-webkit-scrollbar {
    display: none;
  }
}

.call-modal, .tutorial-modal {
  --width: 100%;
  --height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
}

.marker-filters-modal {
  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 620px) {
    --border-radius: 15px;
  }
}

.consumer-modal {
  --width: 100%;
  --height: 100%;
  --background: black;

  ::-webkit-scrollbar {
    display: none;
  }
}

.captcha-modal {
  --border-radius: 15px;
  --width: min(400px, 100%);
  --height: calc(min(400px, 100%) / 1.4);

  ::-webkit-scrollbar {
    display: none;
  }
}